export const environment = {
    banorte: false,
    production: true,
    ente: "H. Ayuntamiento de San Ignacio",
    administracion: "2024 - 2027",
    url_escudo: "../assets/sanignacio/escudo.png",
    url_administracion: "../assets/sanignacio/san_ignacio_logo_admin_24_27.png",
    url_footer: "../assets/sanignacio/san_ignacio_logo_admin_24_27.png",
    direcciones: [
        "San Ignacio, Sin",
        "C.P.82910 ",
        "San Ignacio, Sinaloa"
    ],
    telefonos: [
        "696 962 5103"
    ],
    emails: [
        "transparencia01@sanignacio.gob.mx "
    ],
    url_aviso: "http://sanignacio.gob.mx/cms/informacion-historica/",
    url_webapi: "https://pagossanignacio.azurewebsites.net/api/",
    url_sitio: "https://pagossanignacio.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "010",
    municipio: "SAN IGNACIO",
    firebase: {
        apiKey: "AIzaSyDfDu2e5AzavRgmV6NuizLhi9Hjx_t7eEw",
        authDomain: "pagossanignacio-864e1.firebaseapp.com",
        projectId: "pagossanignacio-864e1",
        storageBucket: "pagossanignacio-864e1.appspot.com",
        messagingSenderId: "561451166151",
        appId: "1:561451166151:web:8e9ce6ce463a07f148d1e2",
        measurementId: "G-NG0WK0KC3P"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de San Ignacio para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    img_ayuda_1: '../assets/sanignacio/img_ayuda_1.jpg',
    img_ayuda_2: '../assets/sanignacio/img_ayuda_2.jpg',
    img_ayuda_3: '../assets/sanignacio/img_ayuda_3.jpg',
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
  };
